import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import Welcome from './components/pages/Welcome'
import Price from './components/pages/price.jsx'
import Navbar from './components/NavBar.jsx'
import Footer from './components/Footer.jsx'
import './App.css'



function App() {
  return (
    <Router>
      <header>
        <Navbar />
      </header>

      <div className="App">
        <Routes>
          <Route 
            path="/"
            element={<Welcome />}
          />
          <Route 
            path="/Price"
            element={<Price />}
          />
        </Routes>
      </div>
      <footer>
        <Footer />
      </footer>
    </Router>
  );
}

export default App;
