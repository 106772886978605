const navigation = {
	main: [
	  { name: 'NS', href: '/' },
	  { name: 'Contact', href: 'mailto:nextstepwebdev@gmail.com' },
	  { name: 'Pricing', href: '/price' },
	//   { name: '', href: '/' },
	//   { name: 'Accessibility', href: '#' },
	//   { name: 'Partners', href: '#' },
	]
  }
  
  export default function Example() {
	return (
	  <footer className="bg-white footer">
		<div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
		  <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
			{navigation.main.map((item) => (
			  <div key={item.name} className="pb-6">
				<a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900" id="selectedTab">
				  {item.name}
				</a>
			  </div>
			))}
		  </nav>
		  <div className="mt-10 flex justify-center space-x-10">
		  </div>
		  <p className="mt-10 text-center text-xs leading-5 text-gray-500">
			{/* &copy; The Next Step LLC. All rights reserved. */}
			Next Step Web Development
		  </p>
		</div>
	  </footer>
	)
  }